import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";

export default function Template({
	data, // this prop will be injected by the GraphQL query below.
}) {
	const { markdownRemark } = data; // data.markdownRemark holds your post data
	const { frontmatter, html } = markdownRemark;
	return (
		<>
			<SEO title={frontmatter.title} type="article" />
			<PoliciesWrapper>
				<div className="article-post">
					<h1>{frontmatter.title}</h1>
					<h5>{frontmatter.date}</h5>
					<div
						className="blog-post-content"
						dangerouslySetInnerHTML={{ __html: html }}
					/>
				</div>
			</PoliciesWrapper>
		</>
	);
}

const PoliciesWrapper = styled.section`
	position: relative;
	display: grid;
	justify-items: center;
	background-color: #fefaf8;
	color: black;
	padding: 100px 30px 100px 30px;
	text-align: justify;

	h2 {
		color: #00cc99;
		font-size: 1.8rem;
		font-weight: bold;
		text-align: left;
	}
`;

export const pageQuery = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			frontmatter {
				date
				slug
				title
			}
		}
	}
`;
